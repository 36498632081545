import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Banner = () => {
  return (
    <Box
      style={{
        pt: "84px",
        pb: "84px",
        minHeight: "100vh",
        mb: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL + "/images/colorLogo.png"}`}
            alt="Logo"
            height="400px"
            width="400px"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Typography
            variant="h2"
            color="secondary"
            align="center"
            sx={{
              fontWeight: "bold",
            }}
          >
            مرحباً
          </Typography>
          <Typography
            variant="h3"
            color="secondary"
            align="center"
            sx={{
              fontWeight: "bold",
              mb: 1,
            }}
          >
            أنا عبدالرحمن الصديق
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={{
              fontWeight: "bold",
              mb: 1,
            }}
          >
            مطور تطبيقات ويب
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
    </Box>
  );
};

export default Banner;
