import { AppBar, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box, Container } from "@mui/system";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Navbar = (props) => {
  return (
    <ElevationScroll {...props}>
      <AppBar color="secondary">
        <Container
          maxWidth="lg"
          sx={{
            px: "0px",
          }}
        >
          <Toolbar
            classes={{
              root: {
                px: 0,
              },
            }}
          >
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <img
                src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
                alt="Logo"
                height="70px"
                width="70px"
              />
            </Box>
            <Typography variant="h6" component="div">
              info@aboudalseddiq.com
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default Navbar;
