import { Grid, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SkillCard from "./SkillCard";

const Skills = () => {
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: (theme) => theme.palette.secondary.main,
      }}
    >
      <Typography
        variant="h4"
        color="#fff"
        align="center"
        sx={{
          mb: 4,
          fontWeight: "bold",
        }}
      >
        {"<  مهاراتي  >"}
      </Typography>
      <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/htmlLogo.svg"}`}
              name="Html"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/cssLogo.svg"}`}
              name="css"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/javaScriptLogo.svg"}`}
              name="JavaScript"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/nodejsLogo.svg"}`}
              name="Nodejs"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/expressLogo.svg"}`}
              name="express"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/mongodbLogo.svg"}`}
              name="mongodb"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/reactLogo.svg"}`}
              name="React"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/muiLogo.svg"}`}
              name="mui"
            />
          </Grid>
          <Grid item>
            <SkillCard
              image={`${process.env.PUBLIC_URL + "/images/bootstrapLogo.svg"}`}
              name="bootstrap"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Skills;
