import "./App.css";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { LightTheme } from "./Themes";
import Routing from "./Routing";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={LightTheme}>
        <div dir="rtl">
          <Routing />
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
