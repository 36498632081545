import { Box } from "@mui/material";
import React from "react";
import { Navbar, Banner, Skills } from "../Components";

const Home = () => {
  return (
    <Box
      sx={{
        mb: 6,
      }}
    >
      <Navbar />
      <Banner />
      <Skills />
    </Box>
  );
};

export default Home;
