import { Paper } from "@mui/material";
import React from "react";

const SkillCard = ({ image, name }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: "center",
        height: "100px",
        width: "100px",
        borderRadius: "10px",
      }}
    >
      <img src={image} alt={name} width="100%" height="100%" />
    </Paper>
  );
};

export default SkillCard;
