import { createTheme } from "@mui/material/styles";

const LightTheme = createTheme({
  palette: {
    mode: "light",
    common: { black: "#000", white: "#fff" },
    background: { paper: "rgba(255, 255, 255, 1)", default: "#fafafa" },
    primary: {
      light: "#e44a5d",
      main: "#de1e35",
      dark: "#b1182a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#213e4b",
      main: "#092938",
      dark: "#07202c",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Almarai", "sans-serif"].join(","),
  },
  direction: "rtl",
});

export default LightTheme;
